import i18next from 'i18next';
import { CboRole, FeatureFlag, Package } from '@cbo/shared-library';
import isPermitted from '../../../lib/permissions';
import { useUsers } from '../../../contexts/userContext';
import routes from '../../../constants/routes';
import { NavItem } from '.';
import { useFeatureFlags } from '../../../utils/hooks/useFeatureFlag';
import { usePackage } from '../../../contexts/packageContext';
import { User } from '../../../models/User';

const getStarterRoutes = (cboLaborRearchitecture: boolean, user: User) => {
  const salesReportsChildren: NavItem[] = [];
  if (isPermitted(user, [CboRole.SALES_SUMMARY_VIEW])) {
    salesReportsChildren.push({
      title: i18next.t('navItems.salesSummaryReport'),
      href: routes.SALES_SUMMARY_REPORT,
      testid: 'sales-summary-report',
    });
  }

  if (cboLaborRearchitecture && isPermitted(user, [CboRole.PROFIT_LOSS_REPORT_VIEW])) {
    salesReportsChildren.push({
      title: i18next.t('navItems.profitLossReport'),
      href: routes.SALES_PROFIT_LOSS_REPORT,
      testid: 'profit-loss-report',
    });
  }

  if (isPermitted(user, [CboRole.PRODUCT_MIX_REPORT_VIEW])) {
    salesReportsChildren.push({
      title: i18next.t('navItems.productMixReport'),
      href: routes.SALES_PRODUCT_MIX_REPORT,
      testid: 'product-mix-report',
    });
  }

  if (isPermitted(user, [CboRole.PAYMENT_REPORT_VIEW])) {
    salesReportsChildren.push({
      title: i18next.t('navItems.paymentReport'),
      href: routes.SALES_PAYMENT_REPORT,
      testid: 'payment-report',
    });
  }

  if (cboLaborRearchitecture && isPermitted(user, [CboRole.REVENUE_CENTER_REPORT_VIEW])) {
    salesReportsChildren.push({
      title: i18next.t('navItems.revenueCenterReport'),
      href: routes.SALES_REVENUE_CENTER_REPORT,
      testid: 'revenue-center-report',
    });
  }

  if (isPermitted(user, [CboRole.TAX_REPORT_VIEW])) {
    salesReportsChildren.push({
      title: i18next.t('navItems.taxesReport'),
      href: routes.SALES_TAX_REPORT,
      testid: 'tax-report',
    });
  }

  if (isPermitted(user, [CboRole.DISCOUNT_REPORT_VIEW])) {
    salesReportsChildren.push({
      title: i18next.t('navItems.discountReport'),
      href: routes.SALES_DISCOUNT_REPORT,
      testid: 'discount-report',
    });
  }

  if (isPermitted(user, [CboRole.REFUND_REPORT_VIEW])) {
    salesReportsChildren.push({
      title: i18next.t('navItems.refundsReport'),
      href: routes.SALES_REFUNDS_REPORT,
      testid: 'refunds-report',
    });
  }

  if (isPermitted(user, [CboRole.VOID_REPORT_VIEW])) {
    salesReportsChildren.push({
      title: i18next.t('navItems.voidReport'),
      href: routes.SALES_VOID_REPORT,
      testid: 'void-report',
    });
  }
  return salesReportsChildren;
};

export default function useSalesRoutes(): NavItem[] {
  const user: User = useUsers();
  const hasStarterPackage = usePackage(Package.STARTER);
  const [cboHouseAccounts, cboLaborRearchitecture] = useFeatureFlags(
    FeatureFlag.houseAccounts,
    FeatureFlag.laborRearchitecture
  );

  // sales reports routes
  const salesReportsChildren: NavItem[] = [];
  if (hasStarterPackage) {
    salesReportsChildren.push(...getStarterRoutes(cboLaborRearchitecture, user));
  }

  const salesRoutes: NavItem[] = [];
  if (salesReportsChildren.length > 0) {
    salesRoutes.push({
      title: i18next.t('navItems.salesReports'),
      testid: 'sales-reports',
      children: salesReportsChildren,
    });
  }

  if (isPermitted(user, [CboRole.TRANSACTION_DETAIL_VIEW])) {
    salesRoutes.push({
      title: i18next.t('navItems.transactionDetailsReport'),
      href: routes.SALES_TRANSACTION_DETAILS_REPORT,
      testid: 'transaction-details-report',
    });
  }

  if (
    hasStarterPackage &&
    cboHouseAccounts &&
    isPermitted(user, [CboRole.HOUSE_ACCOUNT_READER, CboRole.HOUSE_ACCOUNT_WRITER])
  ) {
    salesRoutes.push({
      title: i18next.t('navItems.houseAccounts'),
      href: routes.HOUSE_ACCOUNTS,
      testid: 'sales-house-accounts',
    });
  }

  if (isPermitted(user, [CboRole.POS_EVENT_LOG_VIEW])) {
    salesRoutes.push({
      title: i18next.t('navItems.posEventLog'),
      href: routes.SALES_POS_EVENT_LOG,
      testid: 'pos-event-log',
    });
  }

  return salesRoutes;
}
