import { Employee as EmployeeType, Gender, JobStatus } from '@cbo/shared-library';
import { ActivationTypes, ActivationTypes as EmploymentStatus } from '@cbo/shared-library/types/activation';
import { BaseGridRow } from '../components/DataGrid/DataGrid';
import { BaseReportDataRequest } from '../sales/requests/requests';

export interface BaseEntity {
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  deletedAt: string | null;
  deletedBy: string | null;
}
export interface ContactInfoFormDataSchema {
  altPhoneNumber?: string;
  email: string;
  firstName: string;
  lastName: string;
  middleName: string;
  nickname: string;
  phoneNumber: string;
  formCompletionStatus: EmployeeType.EmployeeFormCompletionStatus;
}

export interface ContactInfoUpdateRequestSchema {
  emailAddress?: string;
  lastName?: string;
  firstName?: string;
  middleName?: string;
  nickname?: string;
  phoneNumber?: string;
  alternatePhoneNumber?: string;
  formCompletionStatus?: EmployeeType.EmployeeFormCompletionStatus;
}

export interface EmployeeContactInformation {
  emailAddress: string;
  lastName: string;
  firstName: string;
  middleName: string;
  nickname: string;
  phoneNumber: string;
  alternatePhoneNumber: string;
}

export interface UpdateEmployeeContactInfoRequestSchema {
  contact: EmployeeContactInformation;
}

// TODO remove orgId or change the patch DTO as orgId is required according to the service
// https://github.com/ncrvoyix-swt-hospitality/cbo-employee-information-service/blob/932a2bd0fdab5003f7afb8d7f471556396183aaf/src/employees/employees.service.ts#L269
export interface EmployeeRequestSchema {
  employeeDto: {
    id?: string;
    exportId?: string;
    employeeRegistrationStatus?: EmployeeType.RegistrationStatus;
  };
  contactInformationDto: ContactInfoUpdateRequestSchema;
}

export interface EmployeeCertificationsDetailsInformation extends BaseEntity {
  id: string;
  certificationId: string;
  nepOrganization: string;
  certificationName: string;
  certificationReference: string;
  certificationExpirationDate: Date;
  isExcluded: boolean;
}

export interface EmployeeEmergencyContactInformation {
  firstName: string;
  lastName: string;
  relationship: EmployeeType.Relationship; // enum
  primaryPhoneNumber: string;
  alternatePhoneNumber: null | string;
  emailAddress: string;
}

export interface EmployeeSelfRequestSchema {
  employeeDto: {
    exportId?: string;
    ncrUserId: string | null;
    employeeRegistrationStatus?: EmployeeType.RegistrationStatus;
  };
  contactInformationDto?: ContactInfoUpdateRequestSchema;
}

export interface UpdateEmployeeRequestSchema {
  employeeDto: { employeeRegistrationStatus?: EmployeeType.RegistrationStatus };
}

export interface AddEmployeeFormSchema {
  email: string;
  firstName: string;
  lastName: string;
}

export interface EmergencyContactFormDataSchema {
  id: string;
  firstName: string;
  lastName: string;
  relationship: string;
  phoneNumber: string;
  altPhoneNumber: string;
  email: string;
  formCompletionStatus: EmployeeType.EmployeeFormCompletionStatus;
}

export interface EmployeeEmergencyContactFormDataSchema {
  id: string;
  firstName: string;
  lastName: string;
  relationship: string;
  phoneNumber: string;
  altPhoneNumber: string;
  email: string;
}

export interface UpdateEmergencyContactRequestSchema {
  name: string;
  relationship: EmployeeType.Relationship;
  primaryPhoneNumber: string;
  alternatePhoneNumber: string | null;
  emailAddress: string;
  formCompletionStatus?: EmployeeType.EmployeeFormCompletionStatus;
}

export interface EmployeePersonalInformation {
  address1: string;
  address2: string;
  city: string;
  state: string;
  county: string;
  postalCode: string;
  country: string;
  picture?: string;
}

export interface EmployeeContactDetailsInformation extends EmployeeContactInformation, EmployeePersonalInformation {
  emergencyContact?: EmployeeEmergencyContactInformation;
}

export interface EmployeeDetailsInfo extends BaseEntity {
  contact: EmployeeContactDetailsInformation;
  certifications: EmployeeCertificationsDetailsInformation[];
  birthDate: string | undefined;
  employeeId: string;
  nepOrganization: string;
}
export interface UpdateEmployeeEmergencyContactRequestSchema {
  contact: {
    emergencyContact: {
      firstName: string;
      lastName: string;
      relationship: EmployeeType.Relationship;
      primaryPhoneNumber: string;
      alternatePhoneNumber: string | null;
      emailAddress: string;
      formCompletionStatus?: EmployeeType.EmployeeFormCompletionStatus;
    };
  };
}

export interface updateCertificationsRequestSchema {
  certificationExpirationDate?: Date;
  certificationName?: string;
  certificationNumber?: string;
}

export interface CertificationFormDataSchema {
  certificationCheckbox: boolean;
  certificationExpirationDate: Date;
  certificationName: string;
  certificationNumber: string;
  formCompletionStatus: EmployeeType.EmployeeFormCompletionStatus;
}

export interface UpdateEmployeeCertificationSchema {
  certificationName?: string;
  certificationReference?: string;
  certificationExpirationDate?: string;
  excluded?: boolean;
  formCompletionStatus?: EmployeeType.EmployeeFormCompletionStatus;
}

export interface UpdateEmployeeCertificationInfoSchema {
  certificationName?: string;
  certificationReference?: string;
  certificationExpirationDate?: string;
  isExcluded?: boolean;
}

export interface CreateEmployeeCertificationSchema {
  certificationName: string;
  certificationReference: string;
  certificationExpirationDate: string;
  excluded: boolean;
}

// TODO LaborGroupSchema move to shared library
export interface LaborGroupSchema {
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  deletedAt: Date | null;
  deletedBy: Date | null;
  id: string;
  name: string;
  description: string;
  nepEnterpriseOrganization: string;
  nepEnterpriseUnit: string;
  jobCodes: string[];
}

export interface PersonalInfoFormDataSchema {
  birthday: string;
  gender: Gender;
  address1: string;
  address2: string;
  city: string;
  postalCode: string;
  country: string;
  state: string;
  formCompletionStatus: EmployeeType.EmployeeFormCompletionStatus;
}

export interface PersonalInfoPageFormDataSchema {
  birthday: string;
  gender: Gender;
  address1: string;
  address2: string;
  city: string;
  postalCode: string;
  country: string;
  state: string;
}

export interface UpdatePersonalInfoSchema {
  birthDate: string;
  gender?: Gender;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  formCompletionStatus: EmployeeType.EmployeeFormCompletionStatus;
}

export interface UpdateEmployeePersonalInfoRequestSchema {
  birthDate: string;
  contact: {
    gender?: Gender;
    address1: string;
    address2?: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
}

export interface UpdateEmployeeConfigurationSchema {
  jobCodeId?: string;
  employmentStatusData?: string;
  startDate?: Date;
  rehireDate?: Date;
  scheduleGroup?: number;
  activationStatus?: EmploymentStatus;
  activationReason?: string;
  activationDetails?: string;
  activationEffectiveDate?: Date | null;
  jobStatus?: JobStatus;
  reportingDate?: Date;
  expectedReturnDateFromLeaveOfAbsence?: Date;
  doNotImportPosTimePunches?: boolean;
  terminationDate?: Date;
  posEmployeeId?: string;
  useMagneticCards?: boolean;
  useDallasKey?: boolean;
  prefersQwertyKeyboard?: boolean;
  eligibleForRehire?: boolean;
}

export interface UpdateEmployeePermissionsSchema {
  posEmployeeId?: string;
  useMagneticCards?: boolean;
  prefersQwertyKeyboard?: boolean;
  useFingerPrintScannerForClockIn?: boolean;
  useFingerPrintScannerForLoginAndManagerApproval?: boolean;
}

export interface JobcodeFormDataSchema {
  jobCodeName: string;
  posJobCodeID: string;
  posAccessLevel: string;
  showJobcodeOnPosSystem: boolean;
  glCode: string;
  excludePayroll: boolean;
  excludeSchedule: boolean;
  laborGroup: string;
}

export interface UpdateJobcodeSchema {
  jobCodeName?: string;
  posJobCodeID?: string;
  posAccessLevelID?: string;
  activationStatus?: ActivationTypes;
  glCode?: string;
  excludeFromPayrollReport?: boolean;
  excludeHoursAndDollarsFromScheduleMetrics?: boolean;
}

export interface UpdateEmployeeDetailsSchemaNew {
  ncrUserId?: string;
  exportId?: string;
  posEmployeeId?: string;
  homeEnterpriseUnitId?: string;
  doNotImportPosTimePunches?: boolean;
  employeeClassification?: JobStatus;
  posConfigs?: UpdateEmployeePermissionsSchema;
  birthDate?: Date | null;
  lastName?: string;
  firstName?: string;
  emailAddress?: string;
  phoneNumber?: string;
  nickName?: string;
}

export interface EmployeeSalesPerformanceCommonRequest extends BaseReportDataRequest {
  jobcodes?: string[];
  dayParts?: string[];
}

export interface EmployeeSalesPerformanceKpiCardsResponse {
  salesPerLaborHour: number;
  salesPerPerson: number;
  checkTimeMinutes: number;
  clockInMinutes: number;
}

export interface EmployeeSalesPerformanceGridDataRow extends BaseGridRow {
  name: string;
  employeePosId: string;
  jobcode: string;
  dayPart: string;
  perPersonAverage: number;
  totalNetSales: number;
  numberOfGuests: number;
  numberOfChecks: number;
  averageCheckTimeMinutes: number;
  chargeTips: number;
  compAmount: number;
  voidAmount: number;
  averageClockInMinutesDifference: number;
  actualHours: number;
  salesPerLaborHour: number;
}

export interface EmployeeSalesPerformanceGridDataResponse {
  results: EmployeeSalesPerformanceGridDataRow[];
}

export interface OvertimeThresholdGridDataRow extends BaseGridRow {
  id: number;
  name: string;
  posEmployeeId: string;
  actualHours: number;
  remainingHours: number;
  expectedTotalHours: number;
  remainingHoursBeforeOvertime: number;
  nextShift: string;
}
export interface OvertimeThresholdGridDataResponse {
  results: OvertimeThresholdGridDataRow[];
}

export interface OvertimeThresholdCommonRequest {
  startDate: string;
  endDate: string;
  siteIds: string[];
  hourThresholdLowerRange?: number;
  hourThresholdHigherRange?: number;
}

export interface OvertimeThresholdKpiCardsResponse {
  exceedingOvertime: number;
  approachingOvertimeMiddle: number;
  approachingOvertimeLower: number;
}

export interface AcaDashboardKPICardsRequest extends BaseReportDataRequest {
  jobType?: string[];
  employeeStatus?: string[];
}

export interface AcaDashboardKPICardsResponse {
  totalPartTimeEmployees: number;
  employeeHoursCountLower: number;
  employeeHoursCountMiddle: number;
  employeeHoursCountUpper: number;
}

export interface UpdateEmployeeJobCodeAssignmentSchema {
  employeeJobCodeRating: number;
}

export interface JobcodeHistoryGridRequest {
  siteIds: string[];
  employeeId: string;
}

export interface JobcodeHistoryGridRow extends BaseGridRow {
  jobCode: string;
  payRate: number;
  effectiveDate: string;
  modifiedDate: string;
  modifiedBy: string;
}

export interface JobcodeHistoryGridRowNew extends BaseGridRow {
  jobProfileId: string;
  jobId: string;
  jobName?: string;
  payRate: number;
  effectiveDate: string;
  modifiedDate: string;
  modifiedBy: string;
}

export interface JobcodeHistoryGridResponse {
  response: JobcodeHistoryGridRow[];
}

export interface JobcodeHistoryGridResponseNew {
  response: JobcodeHistoryGridRow[];
}

export interface UpdateResponse {
  body: {
    message: string;
    rows_affected: number;
  };
}

export enum ACADashboardReports {
  ACA_DASHBOARD = 'ACA_DASHBOARD',
  APPROACHING_HOURLY_THRESHOLDS_DASHBOARD = 'APPROACHING_HOURLY_THRESHOLDS_DASHBOARD',
  EMPLOYEE_AVERAGE_HOURS_WORKED_DASHBOARD = 'EMPLOYEE_AVERAGE_HOURS_WORKED_DASHBOARD',
}

export interface EmployeeBreakReportCommonRequestSchema {
  startDate: string;
  endDate: string;
  siteIds?: string[];
}

export interface EmployeeBreakReportKpiCardsDataResponse {
  overdueBreaks: number;
  upcomingBreaks: number;
}

export interface EmployeeBreakGridDataRow extends BaseGridRow {
  id: number;
  date: string;
  site: string;
  name: string;
  posEmployeeId: string;
  jobcode: string;
  clockIn: string;
  breakType: string;
  breakDueBy: string;
}

export interface EmployeeBreakReportGridDataResponse {
  results: EmployeeBreakGridDataRow[];
}

export interface EmployeeTipGridDataRow extends BaseGridRow {
  id: number;
  name: string;
  posEmployeeId: string;
  netSales: number;
  chargeSales: number;
  totalDeclaredTips: number;
  chargeTipAmount: number;
  salesTipPercent: number;
  chargeTipsPercent: number;
  nonChargeSales: number;
  nonChargeTips: number;
  nonChargeTipsPercent: number;
  salesWithAutoGratuity: number;
  autoGratuityAmount: number;
}

export type TotalChargeTipsData = {
  totalChargeTips: number;
  totalChargesales: number;
  totalChargeTip: number;
  date: string;
};

export type TotalTipsData = {
  totalTips: number;
  totalSales: number;
  totalTip: number;
  date: string;
};
export interface EmployeeTipKPICardsAndChartsData {
  totalChargeTips: TotalChargeTipsData[];
  totalTips: TotalTipsData[];
  totalDeclaredTips: number;
  totalAutoGratuity: number;
}

export interface EmployeeTipReportGridDataResponse {
  results: EmployeeTipGridDataRow[];
}

export interface IntervalSalesAndLaborReportCommonRequestSchema {
  startDate: string;
  endDate: string;
  siteIds?: string[];
  jobCodes: string[];
  laborReportingGroup: string[];
}

export interface IntervalSalesAndLaborGridDataRow extends BaseGridRow {
  hierarchy: string[];
  id: number;
  dayPart: string;
  time: Date;
  netSalesAmount: number;
  forecastedSalesAmount: number;
  salesVariance: number;
  actualHours: number;
  scheduleHours: number;
  laborVariance: number;
  laborCostAmount: number;
  laborPercentage: number;
  salesPerLaborHour: number;
  numberOfGuests: number;
  numberOfChecks: number;
}

export interface IntervalSalesAndLaborGridDataResponse {
  response: IntervalSalesAndLaborGridDataRow[];
}

export interface IntervalSalesAndLaborKPIResponse {
  totalNetSalesAmount: number;
  totalNetSalesComparedToForecastPercentage: number;
  totalLaborHours: number;
  totalLaborHoursComparedToScheduledPercentage: number;
  averageSalesPerLaborHour: number;
}

export interface IntervalSalesLaborAverageSales {
  id: number;
  dayPart: string;
  averageSalesPerLaborHour: number;
  netSales: number;
  laborHours: number;
}

export interface IntervalSalesAndLaborAverageSalesResponse {
  response: IntervalSalesLaborAverageSales[];
}

export interface GenericPayrollExportGridDataRequest {
  startDate: string;
  endDate: string;
  siteIds: string[];
}

export interface GenericPayrollExportGridDataResponse {
  employeeName: string;
  employeePOSId: string;
  jobCode: string;
  payRate: number;
  totalNetSales: number;
  chargeNetSales: number;
  employeeSalesAmountPercent: number;
  regularHoursWorked: number;
  overtimeHoursWorked: number;
  dailyOvertimeHoursWorked: number;
  premiumHoursWorked: number;
  chargeTips: number;
  declaredTips: number;
  employeeTipsAmountPercent: number;
}

export interface ApproachingACAHourlyThresholdGridDataRequest {
  startDate: string;
  endDate: string;
  siteIds: string[];
  jobType?: string[];
  employeeStatus?: string[];
  hourThresholdLowerRange?: number;
  hourThresholdHigherRange?: number;
}

export interface ApproachingACAHourlyThresholdOutput extends BaseGridRow {
  siteName: string;
  employeeName: string;
  employeePosId: string;
  jobType: string;
  employeeStatus: string;
  actualHours: number;
  remainingHours: number;
}

export interface ApproachingACAHourlyThresholdGridDataResponse {
  results: ApproachingACAHourlyThresholdOutput[];
}

export interface ApproachingACAThresholdAverageHoursWorkedRow extends BaseGridRow {
  id: number;
  siteName: string;
  employeeName: string;
  employeePosId: string;
  averageHoursWorked: number;
  ytdAverageHoursWorked: number;
  actualHours: Record<number, number | null>;
}

export interface ApproachingACAThresholdAverageHoursWorkedGridResponse {
  results: ApproachingACAThresholdAverageHoursWorkedRow[];
}

export interface EmployeeIdByPosEmployeeIdResponse {
  employeeId: string;
}

export interface EmployeePayrollDto extends BaseReportDataRequest {
  jobCodeNames?: string[];
}
export interface EmployeePayrollGridResults extends BaseGridRow {
  id: string;
  name: string;
  employeePosId: string;
  jobcode: string;
  payRate: number;
  regularHoursWorked: number;
  regularWages: number;
  overtimeHoursWorked: number;
  overtimeWages: number;
  premiumHoursWorked: number;
  premiumOvertimeWages: number;
  totalHoursWorked: number;
  totalNetSales: number;
  declaredTips: number;
  tipPercent: number;
}

export interface EmployeePayrollGridResponse {
  results: EmployeePayrollGridResults[];
}
export interface EmployeePayrollJobcodeItemData {
  jobcodeName: string;
  percentOfTotal: number;
  wageTotal: number;
}

export interface EmployeePayrollKPICardData {
  laborPercentOfCost: EmployeePayrollJobcodeItemData[];
  totalLiability: number;
}

export interface EmployeePayrollDetailGridDataRow extends BaseGridRow {
  id: number;
  name: string;
  posEmployeeId: string;
  businessDate: string;
  clockIn: string;
  clockOut: string;
  clockEventType: string;
  breakType: string;
  regularHoursWorked: number;
  regularWages: number;
  overTimeHoursWorked: number;
  overTimeWages: number;
  premiumHoursWorked: number;
  premiumOverTimeWages: number;
  penaltyWages: number;
  totalHoursWorked: number;
  totalWages: number;
  totalNetSales: number;
  totalDeclaredTips: number;
  totalDeclaredTipsPercent: number;
  payments: number;
}

export interface EmployeePayrollDetailDataGridResponse {
  results: EmployeePayrollDetailGridDataRow[];
}

export interface OvertimeThresholds {
  lowerThreshold: number;
  middleThreshold: number;
  upperThreshold: number;
}

export interface ActualHoursWorked {
  lower?: number;
  higher?: number;
}

export interface Job extends BaseEntity {
  jobId: string;
  laborGroupId?: string;
  nepOrganization?: string;
  name: string;
  description?: string;
  posJobCodeId?: string;
  excludeFromPayrollReport?: boolean;
  excludeHoursAndDollarsFromScheduleMetrics?: boolean;
  jobProfileCount?: number;
  isActive: boolean;
}

export interface UpdateJobDto {
  laborGroupId?: string;
  name: string;
  description?: string;
  posJobCodeId?: string;
  isActive: boolean;
  excludeFromPayrollReport?: boolean;
  excludeHoursAndDollarsFromScheduleMetrics?: boolean;
}

export interface GetPaginatedJobsRequest {
  ids?: string[];
  includeJobProfileCount?: boolean;
}

export interface ChangeJobActivationStatusDto {
  activationStatus: boolean;
}

export interface LaborGroup extends BaseEntity {
  laborGroupId: string;
  nepOrganization: string;
  name: string;
  description: string;
  jobs: [
    {
      jobId: string;
    }
  ];
}

export interface JobProfileHistory extends BaseEntity {
  performanceRating: number;
  startDate: string;
  endDate: string;
  payRate: number;
}

export interface JobProfile extends BaseEntity {
  jobProfileId: string;
  nepOrganization: string;
  employeeId: string;
  jobId: string;
  performanceRating: number;
  startDate: string;
  endDate?: string;
  payRate: number;
  history?: JobProfileHistory[];
}

export interface UpdateJobProfileDto {
  performanceRating?: number;
  startDate?: string;
  endDate?: string;
  payRate?: number;
}

export enum EmploymentStatusNew {
  PENDING = 'PENDING',
  HIRED = 'HIRED',
  TERMINATED = 'TERMINATED',
  LEAVE_OF_ABSENCE = 'LEAVE_OF_ABSENCE',
  INACTIVE = 'INACTIVE',
}

export interface EmploymentHistoryIdResponse {
  employmentHistoryId: string;
}

export interface EmploymentHistory extends BaseEntity {
  employmentHistoryId?: string;
  nepOrganization?: string;
  employmentStatus: EmploymentStatusNew;
  statusName: string;
  reason: string;
  effectiveDate?: Date | null;
  eligibleForRehire?: string | null;
  expectedReturnDateFromLeaveOfAbsence?: Date | string | null;
}

export interface POSConfigs {
  useMagneticCards: boolean;
  prefersQwertyKeyboard: boolean;
  useFingerPrintScannerForClockIn: boolean;
  useFingerPrintScannerForLoginAndManagerApproval: boolean;
}

export interface LaborJobProfileEmployees {
  jobId: string;
  laborGroupId?: string;
  nepOrganization?: string;
  name: string;
  description?: string;
  posJobCodeId?: string;
}

export interface ContactInformationDetails {
  emailAddress: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  nickname: string;
  phoneNumber: string;
  alternatePhoneNumber?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  county?: string;
  postalCode?: string;
  country?: string;
  emergencyContact?: EmployeeEmergencyContactInformation;
}

export interface EmployeeNew extends BaseEntity {
  id?: string;
  employeeId: string;
  posEmployeeId: string;
  nepOrganization: string;
  ncrUserId: string;
  homeEnterpriseUnitId: string;
  posConfigs: POSConfigs;
  doNotImportPosTimePunches: boolean;
  employeeClassification: JobStatus;
  employmentStatus: EmploymentStatusNew;
  birthDate: Date | null;
  employmentHistory: EmploymentHistory[];
  contact: ContactInformationDetails;
  certifications: EmployeeCertificationsDetailsInformation[];
  exportId: string;
  jobProfileCount: number;
}

export interface UpdateEmployeeHistorySchema {
  employmentStatus: string;
  statusName: string;
  reason?: string;
  effectiveDate: Date | null | undefined;
  eligibleForRehire?: boolean;
  expectedReturnDateFromLeaveOfAbsence?: Date | null;
}

export interface UpdateEmployeeNewRequestSchema {
  ncrUserId?: string;
  exportId?: string;
  posEmployeeId?: string;
  homeEnterpriseUnitId?: string;
  doNotImportPosTimePunches?: false;
  employeeClassification?: JobStatus;
  posConfigs?: string;
  birthDate?: string;
  lastName?: string;
  firstName?: string;
  emailAddress?: string;
  phoneNumber?: string;
  nickname?: string;
}

export interface GetEmployeesRequestSchema {
  pagenum?: number;
  pagesize?: number;
  employmentStatus?: string;
  posEmployeeId?: string;
  employeeIds?: string[];
  includeJobProfileCount?: string;
}
